<template>
  <div class="icon-list">
    <div
      class="icon-item"
      :class="{ active: key == icon.id }"
      v-for="icon in list"
      :key="icon.id"
      @click="select(icon.id)"
    >
      <img :src="icon.img" class="icon-item-img" @click="showImg(icon)" />
      <div class="icon-item-name ellipsis">{{ icon.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconList",
  model: {
    event: "change",
  },
  props: {
    value: Number,
    list: { type: Array, default: () => [] },
  },
  computed: {
    key: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    select(id) {
      if (this.key == id) return;
      this.key = id;
    },
    showImg(icon) {
      if (this.key == icon.id) {
        this.$store.dispatch(
          "showImgList",
          this.list.map((item) => ({ title: item.name, url: item.img })),
          icon.img
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.icon-list {
  margin-right: -24px;
  margin-top: -24px;
}
.icon-item {
  width: 96px;
  display: inline-block;
  margin-right: 24px;
  margin-top: 24px;
  cursor: pointer;
  padding: 8px 8px 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  &.active {
    border: 1px solid #ffce7e;
  }
}
.icon-item-img {
  display: block;
  width: 80px;
  height: 90px;
  object-fit: cover;
}
.icon-item-name {
  text-align: center;
  padding: 4px 0;
}
</style>
