<template>
  <div class="flex-box vertical">
    <a-form-model
      class="flex-grow"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="区块名称" prop="name">
        <a-input v-model="form.name" placeholder="输入区块名称（最多10字）" :maxLength="10" allow-clear></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属区域" prop="district_id">
        <a-select
          placeholder="选择所属区域"
          :options="areaList"
          v-model="form.district_id"
          allow-clear
          @change="districtChange"
        >
        </a-select>
        <template v-slot:extra>
          <div v-show="showTip" class="district-tip">该区域无空余龛位，请选择其他区域</div>
        </template>
      </a-form-model-item>
      <a-form-model-item prop="buddhist_niche_id">
        <template v-slot:label>
          <span>
            <span>选择龛位</span>
            <span class="sub-label">（点击选中，再次点击，选中两次点击中间的龛位）</span>
          </span>
        </template>
        <SelectArea
          v-model="form.buddhist_niche_id"
          multiple
          area
          :id="form.id"
          :list="list"
          :block-list="blockList"
        />
      </a-form-model-item>
      <a-form-model-item label="是否对外开放" prop="status">
        <a-switch v-model="form.status"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="设置图标" prop="type">
        <IconList v-model="form.type" :list="iconList" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { clone } from "../../../common/js/tool";
import { dealAreaList } from "../light";
import SelectArea from "./SelectArea.vue";
import IconList from "./IconList.vue";

export default {
  name: "AreaForm",
  components: {
    SelectArea,
    IconList,
  },
  props: {
    initial: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    showIcon: Boolean,
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "请输入区块名称", trigger: "blur" }],
        district_id: [{ required: true, message: "请选择所属区域", trigger: "change" }],
        buddhist_niche_id: [{ required: true, message: "请选择龛位", trigger: "change" }],
        status: [{ required: true, message: "请选择是否开放", trigger: "change", type: "boolean" }],
        type: [{ required: true, message: "请选择图标", trigger: "change" }],
      },
      areaList: [],
      iconList: [],
      showTip: false,
    };
  },
  computed: {
    list() {
      let target = this.areaList.find((area) => area.id == this.form.district_id);
      return target?.list || [];
    },
    blockList() {
      let target = this.areaList.find((area) => area.id == this.form.district_id);
      return target?.area || [];
    }
  },
  watch: {
    initial() {
      this.init();
    },
  },
  async created() {
    await this.getIcon();
    await this.getArea();
    this.init();
  },
  methods: {
    getArea() {
      return this.$axios({
        url: "/admin/mini-worship-district",
        method: "GET",
        params: {
          expand: "buddhistNiche.worshipStatus,area.buddhistNiche",
        },
        noTempleFilter: true,
      }).then((res) => {
        let areaList = res.data;
        dealAreaList(areaList);
        this.areaList = areaList;
      });
    },
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        form.status = form.status == 1;
        this.form = form;
      } else {
        this.form = {
          type: this.iconList[0]?.id, // 默认选择第一个图标
          status: true,
        };
      }
    },
    getIcon() {
      return this.$axios({
        url: "/admin/mini-worship-type",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 999999,
        },
      }).then((res) => {
        this.iconList = res.data;
      });
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            let form = clone(this.form);
            form.status = form.status ? 1 : 2;
            form.buddhist_niche_id = form.buddhist_niche_id?.map((item) => item.id).join(",");
            resolve(form);
          } else {
            reject();
          }
        });
      });
    },
    confirm(formData) {
      this.form = formData;
      this.visible = false;
    },
    districtChange() {
      this.$set(this.form, "buddhist_niche_id", null);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  min-height: 32px;
  line-height: 32px;
}
.input-item {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.special-item {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ant-input-number {
  width: 240px;
}
.common-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.select-item {
  display: inline-block;
  ::v-deep &.limit-item {
    margin-left: 32px;
    .ant-form-item-label {
      line-height: 32px;
    }
  }
  .ant-select {
    width: 240px;
  }
}
.del-btn {
  margin-left: 64px;
  line-height: 32px;
}
.district-tip {
  color: #f5222d;
}
.sub-label {
  color: gray;
}
</style>
